.hero {
  position: relative;
  /* background-color: #010101; */
}
/* .hero:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: url("../../assets/images/bg-wave.svg");
  background-size: cover;
  background-position: 80%;
} */

.hero-text-wrapper h1 {
  font-size: clamp(1.9rem, 4vw, 3rem);
  line-height: 1;
  margin-bottom: 2rem;
  text-transform: capitalize;
}
.hero-text-wrapper p {
  font-size: clamp(1.1rem, 2vw, 1.2rem);
}
/* .typewriter {
  overflow: hidden;
  border-right: 4px solid var(--text-color);
  animation: caret 0.75s steps(1) infinite;
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
} */
.type {
  animation: 0.5s ease infinite blink;
}
/* .img_wrapper {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}
.img_wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(89, 17, 77, 0.7) 0%,
    rgba(152, 87, 49, 0.7) 35%,
    rgba(42, 91, 133, 0.7) 73%,
    rgba(89, 17, 77, 0.9) 100%
  );
} */
.img {
  aspect-ratio: 3/4;
  border-radius: 15px;
  object-fit: cover;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 1023px) {
  .hero button {
    margin: 0 auto;
  }
}
