.card {
  padding: 10px;
}
.card > div {
  padding: 40px 20px;
  border: 1px solid rgb(255 255 255 / 0%);
  border-radius: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  transition: 0.3s ease;
}
.card:hover > div {
  border: 1px solid rgb(255 255 255 / 10%);
  transform: scale(1.03);
}
.card h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
.card svg {
  font-size: 50px;
  margin-bottom: 2rem;
}
