.hamburger_menu {
  display: none;
}
.hamburger_menu > span {
  width: 25px;
  height: 2px;
  background-color: var(--text-color);
  display: block;
  transition: 0.5s ease;
}
.hamburger_menu > span:not(:last-child) {
  margin-bottom: 6px;
}
.hamburger_menu > span:nth-child(2) {
  width: 17px;
  margin-left: auto;
  transition: none;
}
.hamburger_menu.open > span:first-child {
  transform: rotate(45deg) translate(4px, 7px);
}
.hamburger_menu.open > span:nth-child(2) {
  width: 0;
}
.hamburger_menu.open > span:last-child {
  transform: rotate(-45deg) translate(4px, -8px);
}
.mobile_menu_wrapper {
  width: 330px;
  max-width: 90%;
  position: fixed;
  left: -350px;
  top: 0;
  z-index: 9999;
  background-color: var(--bg-2);
  height: 100%;
  transition: 0.5s ease;
  box-shadow: 0 10px 3px rgb(0 0 0 / 20%);
}
.mobile_menu_wrapper.open {
  left: 0;
}
.menu {
  padding: 4rem 2rem;
  font-size: 1.5rem;
  width: 100%;
}
.menu li {
  list-style: none;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .hamburger_menu {
    display: block;
  }
}
