.box {
  background-color: #121212;
  border: 2px solid #1a1a1a;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0 4px 4px #00000040, 0 0 14px #25252540, 0 0 48px #2d2d2d1f;
}
.logos {
  z-index: 1;
  grid-column-gap: 16px;
  grid-row-gap: 30px;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: center;
  place-items: center;
  display: grid;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
}
.logos > img {
  max-width: 88px;
  justify-content: center;
  border-radius: 10px;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.logos > img.zeal {
  max-width: 120px;
  z-index: 10;
}
.logos_wrapper {
  position: relative;
}
.logos_wrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url("../../assets/images/logos-reflection.svg");
  background-size: cover;
  background-position: center;
}
@media (max-width: 465px) {
  .logos > img {
    width: 60px;
  }
  .logos > img.zeal {
    width: 75px;
  }
  .logos {
    padding: 10px;
  }
}
