/* Navbar.css */

.navbar {
  padding: 1rem 2rem;
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.navbar.sticky {
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--rgb-20);
}
.navbar a {
  text-decoration: none;
  color: black;
  display: flex;
  position: relative;
  z-index: 2;
}

.logo svg,
.logo {
  width: 150px;
}
.navbar a {
  color: inherit;
}
.navlinks {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.navlinks li {
  position: relative;
  transition: 0.2s ease;
}
.navlinks li:not(:last-child) {
  margin-right: 20px;
}
.navlinks li:not(:last-child):before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 0;
  height: 1px;
  background-color: var(--accent-color);
  transition: 0.2s ease;
}
.navlinks li:not(:last-child):hover {
  color: var(--accent-color);
}
.navlinks li:not(:last-child):hover:before {
  width: 100%;
}
.header_icons {
  border-left: 1px solid var(--rgb-50);
  padding-left: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.toggle_mode {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--rgb-20);
  border-radius: 50px;
}
.toggle_mode span {
  display: flex;
  overflow: hidden;
}
.toggle_mode span svg {
  animation: fade-in 0.5s;
}
.contact {
  padding: 8px 2rem;
  background: transparent;
  color: var(--text-color);
  background-color: var(--bg);
  border-radius: 25px;
  position: relative;
  transition: 0.5s ease;
  overflow: hidden;
  font-weight: 600;
  border: 1px solid rgb(255 255 255 / 60%);
  transition: 0.5s ease;
}
.contact:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  transform: translateX(-100%);
  transition: 0.5s ease;
  background-color: var(--accent-color);
}
.contact:hover:before {
  transform: translateX(0%);
}
.contact:hover {
  border-color: var(--accent-color);
}
.language {
  position: relative;
}
.language > span {
  padding: 0.5rem;
  border-radius: 10px;
  background: var(--bg-2);
}
.language_select {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: -0.5rem;
  padding: 0.5rem 0;
  background: var(--bg-2);
  z-index: 9999;
  border-radius: 10px;
  border: 1px solid var(--bg);
  clip-path: inset(0 0 100% 0);
  transition: 0.5s ease;
}
.language_select > span {
  display: block;
  padding: 0 0.5rem;
  text-align: center;
}
.language_select.show {
  clip-path: inset(0 0 0 0);
}
.language_select > span:not(:last-child) {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bg);
}

@keyframes fade-in {
  0% {
    transform: translateY(40px);
  }
  100% {
    transform: translateY(0);
  }
}
@media (max-width: 768px) {
  .navlinks > li:not(.contact) {
    display: none;
  }
  .logo svg,
  .logo {
    width: 100px;
  }
}
@media (max-width: 576px) {
  .contact {
    display: none;
  }
  .header_icons {
    border-left: none;
    padding-left: none;
  }
  .navbar {
    padding: 0.5rem;
  }
}
