.footer {
  border-top: 1px solid rgb(255 255 255 / 20%);
}
.footerLogoWrapper {
  padding-right: 1rem;
  border-right: 1px solid rgb(255 255 255 / 20%);
  display: flex;
  align-items: center;
}
.developer {
  padding: 20px;
  border-top: 1px solid rgb(255 255 255 / 20%);
  text-align: center;
}
.developer a {
  color: var(--accent-color);
}
.footerLinksWrapper,
.footerContact {
  padding-left: 2rem;
}
.footerLogo {
  max-width: 200px;
  margin-bottom: 2rem;
}
.footerLinksWrapper {
  border-right: 1px solid rgb(255 255 255 / 20%);
}
.footerLinks ul {
  list-style: none;
  padding: 0;
}
.footerLinks p,
.footerContact span {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}
.footerLinks p::before,
.footerContact span:before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -5px;
  width: 35%;
  height: 2px;
  background-color: var(--accent-color);
}
.footerContact p {
  margin-bottom: 0.7rem;
}
.footerLinks ul li {
  margin-bottom: 0.7rem;
}
.footerLinks ul li a {
  transition: 0.5s ease;
  display: flex;
  align-items: center;
  gap: 5px;
}
.footerLinks ul li a span {
  display: flex;
}
.footerLinks ul li a:hover {
  color: var(--accent-color);
}
.footerContent > div > div {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (max-width: 768px) {
  .footerContent > div div {
    padding: 1rem 0;
  }
  .footerLogo {
    margin: 0 auto 1rem;
  }
  .footerLogoWrapper {
    border-right: none;
    text-align: center;
    padding-right: 0;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
  }
  .footerLinksWrapper {
    border-right: none;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
  }
}
@media (max-width: 576px) {
  .footerContent.footerPadding {
    padding-top: 1rem;
    margin-top: 1rem;
  }
  .footerLinksWrapper {
    padding-left: 0;
  }
}
