@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic:ital,wght@600&family=Roboto+Flex:wght@300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --text-color: #fbf9ff;
  --accent-color: #f7b32b;
  --bg: #0e0f11;
  --bg-2: #18191d;
  --text-color-alt: rgba(255, 255, 255, 0.7);
  /* #F7B32B */
}
body,
html {
  max-width: 100vw;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  color: var(--text-color-alt);
  background-color: var(--bg);
  font-family: "Roboto Flex", sans-serif;
  font-weight: 300;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Didact Gothic", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color: var(--text-color);
}
.pre-heading {
  color: var(--accent-color);
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  border-bottom: 2px solid;
  margin-bottom: 1rem;
}

.heading {
  font-size: clamp(1.9rem, 3vw, 2.5rem);
  line-height: 1.3;
  margin-bottom: 2rem;
  text-transform: capitalize;
}
::selection {
  background-color: var(--text-color);
  color: var(--bg);
}
