.banner {
  /* background: url("../../assets/IMG_1849.JPG"); */
  background-size: cover;
  background-position: center;
  position: relative;
}
.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(89, 17, 77, 0.7) 0%,
    rgba(152, 87, 49, 0.7) 35%,
    rgba(42, 91, 133, 0.7) 73%,
    rgba(89, 17, 77, 0.9) 100%
  );
}
