.img {
  aspect-ratio: 3/4;
  object-fit: cover;
}
.swiper {
  position: relative;
}
.navigation {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  display: flex;
  gap: 10px;
}
.navigation button svg {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation button {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  /* border: 1px solid #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 /50%);
  transition: 0.3s ease;
}
.navigation button:hover {
  background-color: rgb(0 0 0 /70%);
}
