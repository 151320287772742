.card > div {
  background-color: var(--bg-2);
  border-radius: 10px;
  margin: 10px;
  padding: 48px;
  height: calc(100% - 20px);
  transition: 0.3s ease;
}
.card:hover > div {
  transform: scale(1.03);
}
.card > div > span {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 70px;
}

.card > div > div {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  background: var(--accent-color);
  border-radius: 10px;
}

.card > div > div > svg {
  width: 30px;
  height: 30px;
  color: #fff;
}
