.btn {
  border: 2px solid rgb(255 255 255 / 30%);
  /* background-color: var(--bg); */
  padding: 0.7rem 2rem;
  border-radius: 50px;
  overflow: hidden;
  position: relative;
  display: flex;
  transition: color 0.2s, opacity 0.5s cubic-bezier(0.21, 0.6, 0.35, 1),
    transform 0.4s cubic-bezier(0.21, 0.6, 0.35, 1);
}
.btn:hover {
  border-color: var(--text-color);
  color: var(--text-color);
}
.btn span {
  transition: color 0.2s, opacity 0.5s cubic-bezier(0.21, 0.6, 0.35, 1),
    transform 0.4s cubic-bezier(0.21, 0.6, 0.35, 1);
  display: block;
}
.btn span:last-child {
  position: absolute;
  transform: translateY(90%);
  opacity: 0;
}
.btn:hover > span:last-child {
  transform: translateY(0%);
  opacity: 1;
}
.btn:hover > span:first-child {
  transform: translateY(-90%);
  opacity: 0;
}
