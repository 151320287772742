.socials {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}
.socials > a {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--text-color-alt);
  transition: 0.3s ease;
}
.socials > a:hover {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}
.socials svg {
  width: 20px;
  height: 20px;
  color: var(--text-color-alt);
}
.wrapper p {
  margin-bottom: 0.7rem;
}
